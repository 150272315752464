import { injectable, inject } from 'inversify';
import { BaseProgressGoalComponent } from './base-progress-goal-component';
import { formatCurrencyNoDecimals } from '../../../../utils/utils';

declare const Chart: any;
declare const window: any;

@injectable()
export class ProgressGoalChartComponent extends BaseProgressGoalComponent{
  //
  private interval: any;
  private hideLegends: any;
  private hideTopGoal: any;
  private startFromTop: any;
  private showCenterGoal: any;
  private showRaisedLegends: any;
  //
  private cutoutPercentage: number;
  private borderWidth: number;
  //
  private colorDonations: string;
  private colorSponshorships: string;
  private colorRetailSales: string;
  private colorBackground: string;
  //
  //
  constructor() {
    super();
    this.componentId = 'fr-progress-goal-chart';
    this.title = 'Progress Goal Chart';
    //
  }

  public fillData(data): void {
    super.fillData(data);
    const dataset = this.domElement.get(0).dataset;
    this.hideTopGoal = dataset.hideTopGoal != null ? (dataset.hideTopGoal === 'true') : false;
    this.hideLegends = dataset.hideLegends != null ? (dataset.hideLegends === 'true') : false;
    this.showCenterGoal = dataset.showCenterGoal != null ? (dataset.showCenterGoal === 'true') : false;
    this.showRaisedLegends = dataset.showRaisedLegends != null ? (dataset.showRaisedLegends === 'true') : false;
    //
    this.startFromTop = dataset.startFromTop != null ? (dataset.startFromTop === 'true') : false;
    this.cutoutPercentage = dataset.cutoutPercentage != null ? parseInt(dataset.cutoutPercentage, 10) : 50;
    this.borderWidth = dataset.borderWidth != null ? parseInt(dataset.borderWidth, 10) : 1;
    //
    this.colorDonations = dataset.colorDonations != null ? dataset.colorDonations : '#5082BC';
    this.colorSponshorships = dataset.colorSponshorships != null ? dataset.colorSponshorships : '#BF504E';
    this.colorRetailSales = dataset.colorRetailSales != null ? dataset.colorRetailSales : '#9ABA58';
    this.colorBackground = dataset.colorBackground != null ? dataset.colorBackground : '#C0C0C0';
  }

  public getView(): string {
    // Do not show widget if content is empty
    let view =  this.getTitleView() +
      '<div class="fr-widget__body">';
    if (!this.hideTopGoal) {
      view += '<div class="chart-goal">' +
        ' <span>' + formatCurrencyNoDecimals(this.donationsAmount + this.sponsorshipsAmount + this.retailSalesAmount) +
        ' of </span><span class="chart-goal-total">' + formatCurrencyNoDecimals(this.frGoal) + '</span>' +
        '</div>';
    }
    if (this.showCenterGoal) {
      view += '<div class="chart-center-goal">' +
        '<div class="chart-goal-text">Goal</div> ' +
        '<div class="chart-goal-total">' + formatCurrencyNoDecimals(this.frGoal) + '</div>' +
        '</div>';
    }
    view += `<div class="doughnut-chart">
              <canvas class="doughnut-chart-canvas"></canvas>
              <div id="percentageOverlay"></div>
             </div>`;
    if (!this.hideLegends) {
      view +=
        '<div class="doughnut-legends">';
      view += this.getLegend('donations', 'Donations', this.donationsAmount);
      view += this.getLegend('sponsorships', 'Sponsorships', this.sponsorshipsAmount);
      view += this.getLegend('retail-sales', 'Retail Sales', this.retailSalesAmount);
      view += '</div> ';
    }
    if (this.showRaisedLegends) {
      view +=
        '<div class="doughnut-legends">';
      if (this.page.fundraiserType === 'TEAM' || this.page.fundraiserType === 'NATIONAL_TEAM') {
        view += '<div class="raised-value">' +
          'Raised:&nbsp;' + formatCurrencyNoDecimals(this.donationsAmount + this.sponsorshipsAmount + this.retailSalesAmount) +
          ' </div>';
      } else {
        view += '<div class="raised-value">' +
          'Raised:&nbsp;' + formatCurrencyNoDecimals(this.page.fundraiserTotalDonationAmount + this.page.teamMemberSponsorshipPromised) +
          ' </div>';
      }
      if (this.page.fundraiserType === 'TEAM_MEMBER') {
        view += '<div class="raised-value team-member-raised">' +
          'Team Goal:&nbsp;' + formatCurrencyNoDecimals(this.teamThermometer.goal) +
          '&nbsp; Raised:&nbsp;' + formatCurrencyNoDecimals(this.teamThermometer.raised) +
          ' </div>';
      }

      view += '</div> ';
    }
    view += '</div>';
    return view;
  }

  public getLegend(id: string, name: string, value: number): string {
    const view = ' <div class="doughnut-legend doughnut-legend-' + id + '">' +
    '   <div class="doughnut-legend-label-container">' +
    '     <div class="doughnut-legend-color">&nbsp;</div> ' +
    '     <div class="doughnut-legend-label">' + name + '</div>' +
    '   </div> ' +
    '   <div class="doughnut-legend-value">' + formatCurrencyNoDecimals(value) + '</div> ' +
    '  </div>';
    return view;
  }

  private getData(): number[] {
    let data;
    if (this.page.fundraiserType === 'INDIVIDUAL' || this.page.fundraiserType === 'TEAM_MEMBER') {
      data = [
        this.page.fundraiserTotalDonationAmount,
        this.page.teamMemberSponsorshipPromised,
        0,
        this.remaining
      ];
    } else {
      data = [
        this.donationsAmount,
        this.sponsorshipsAmount,
        this.retailSalesAmount,
        this.remaining
      ];
    }
    return data;
  }

  public afterView(): boolean {
    let rotation = +0.5;
    if (this.startFromTop) {
      rotation = -0.5;
    }
    //
    const doughnutData = {
      type: 'doughnut',
      data: {
        datasets: [{
          data: this.getData(),
          backgroundColor: [
            this.colorDonations,
            this.colorSponshorships,
            this.colorRetailSales,
            this.colorBackground,
          ],
          borderWidth: this.borderWidth,
        }],
        labels: [
          'Donations',
          'Sponsorships',
          'Retail Sales',
          '',
        ],
      },
      options: {
        rotation: rotation * Math.PI,
        cutoutPercentage: this.cutoutPercentage,
        responsive: true,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },
      },
    };

    //determine the percentage of completion
    let total = -1;
    let percentage = -1;
    if (this.page.fundraiserType === 'INDIVIDUAL' || this.page.fundraiserType === 'TEAM_MEMBER') {
      total = this.individualThermometer.raised + this.remaining;
      percentage = this.individualThermometer.raised == 0 ? 0 : Math.ceil((this.individualThermometer.raised / total) * 100);
    }

    if (this.page.fundraiserType === 'TEAM' || this.page.fundraiserType === 'NATIONAL_TEAM') {
      const raised = this.donationsAmount + this.sponsorshipsAmount + this.retailSalesAmount;

      total = raised + this.remaining;
      percentage = raised == 0 ? 0 : Math.ceil((raised / total) * 100);
    }

    const percentageOverlay = function(chart) {
      //make sure we have all the data required to render the percentage.
      if(total == -1 || percentage == -1)
        return;

      //do some path to determine the size of the circle we are rendering the percentage on
      const radius = chart.outerRadius - 12;
      const angle = percentage * 3.6 - 90; //this sets the starting position to 12 oclock
      const radians = angle * 0.0174533;

      //calcuate the cordinates on the circle
      const x = radius * Math.cos(radians);
      const y = radius * Math.sin(radians);

      //build out the percentage element
      const html = `<div style="top: ${y}px; left: ${x}px; display: block"><div class="inner">${percentage}%</div></div>`;
      document.getElementById("percentageOverlay").innerHTML = html; 
    };

    //prepare the chart
    const canvas:any = window.jQuery('.doughnut-chart-canvas').get(0);
    const relative = window.jQuery('.doughnut-chart');
    canvas.width = window.jQuery(relative).width();
    canvas.height = window.jQuery(relative).width();
    const w = canvas.width;
    const h = canvas.height;
    const size = (w > h) ? h : w; // set the radius of the circle to be the lesser of the width or height;
    const ctx = canvas.getContext('2d');
    ctx.beginPath();
    ctx.arc(w / 2, w / 2, size / 2, 0, Math.PI * 2, false);
    ctx.closePath();
    ctx.fill();
    
    //initilize the chart
    const initChart = function(){
      //Add the percentage overflow
      Chart.pluginService.register({
        afterDraw: percentageOverlay
      });
      const chart = new Chart(ctx, doughnutData);
    }

    if (typeof(Chart) !== 'undefined' && Chart != null) 
      initChart();
    else 
      window.chartCallback = initChart;
  
    return true;
  }
}